import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IModalStyleProps,
  IModalStyles,
  IStyleFunctionOrObject,
  PrimaryButton
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { useEffect, useMemo, useState } from "react";

const dialogStyles: IStyleFunctionOrObject<IModalStyleProps, IModalStyles> = {
  main: { maxWidth: "25rem" }
};

interface Props {
  executeOnClick: () => void;
  hideDialog: boolean;
  toggleHideDialog: () => void;
  title: string;
  type?: DialogType;
  subText?: string;
  contentList?: string[];
}

export const ConfirmDialog = ({
  executeOnClick,
  hideDialog,
  toggleHideDialog,
  type = DialogType.normal,
  title,
  subText,
  contentList
}: Props) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [countdown, setCountdown] = useState(3);

  const labelId: string = useId("dialogLabel");
  const subTextId: string = useId("subTextLabel");

  const modalProps = useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles
    }),
    [labelId, subTextId]
  );

  useEffect(() => {
    if (!hideDialog) {
      if (countdown > 0) {
        const interval = setInterval(() => {
          setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);

        return () => clearInterval(interval);
      } else if (countdown === 0) {
        setIsButtonDisabled(false);
      }
    }
  }, [countdown, hideDialog]);

  const closeDialog = () => {
    setIsButtonDisabled(true);
    setCountdown(3);
    toggleHideDialog();
  };

  return (
    <Dialog
      hidden={hideDialog}
      onDismiss={closeDialog}
      dialogContentProps={{
        type,
        title,
        subText
      }}
      modalProps={modalProps}
    >
      <ul>
        {contentList?.map((text, index) => (
          <li key={index}>{text}</li>
        ))}
      </ul>

      <DialogFooter>
        <PrimaryButton
          onClick={executeOnClick}
          text={isButtonDisabled ? `Aguarde ${countdown}s` : "Atualizar"}
          disabled={isButtonDisabled}
        />

        <DefaultButton onClick={closeDialog} text="Não atualizar" />
      </DialogFooter>
    </Dialog>
  );
};
