import {
  ContractStatus,
  TranslatedContractCurrentStep,
  TranslatedContractNextStep
} from "../enums";

export const contractStatusOptions = [
  {
    key: ContractStatus.IN_PROGRESS,
    text: TranslatedContractCurrentStep.IN_PROGRESS
  },
  {
    key: ContractStatus.DOCUMENTS_SENT,
    text: TranslatedContractNextStep.DOCUMENTS_SENT
  },
  {
    key: ContractStatus.DOCUMENTS_APPROVED,
    text: TranslatedContractNextStep.DOCUMENTS_APPROVED
  },
  {
    key: ContractStatus.CREDIT_APPROVED,
    text: TranslatedContractNextStep.CREDIT_APPROVED
  },
  {
    key: ContractStatus.COMMERCIALLY_APPROVED,
    text: TranslatedContractNextStep.COMMERCIALLY_APPROVED
  },
  {
    key: ContractStatus.CLIENT_SIGNED,
    text: TranslatedContractNextStep.CLIENT_SIGNED
  },
  {
    key: ContractStatus.OPTIONAL_SIGNATURES_COLLECTED,
    text: TranslatedContractNextStep.OPTIONAL_SIGNATURES_COLLECTED
  },
  {
    key: ContractStatus.DELIVERED,
    text: TranslatedContractNextStep.DELIVERED
  },
  {
    key: ContractStatus.DONE,
    text: TranslatedContractCurrentStep.DONE
  },
  {
    key: ContractStatus.TIMED_OUT,
    text: TranslatedContractNextStep.TIMED_OUT
  },
  {
    key: ContractStatus.REJECTED,
    text: TranslatedContractCurrentStep.REJECTED
  },
  {
    key: ContractStatus.CANCELED,
    text: TranslatedContractCurrentStep.CANCELED
  },
  {
    key: ContractStatus.SETUP_PAID,
    text: TranslatedContractCurrentStep.SETUP_PAID
  }
];
