import { FiltersTypeEnum } from "./enums";

export enum TranslatedContractTypeTag {
  DOCUMENTS_SENT = "Aguardando documento(s)",
  DOCUMENTS_APPROVED = "Aguardando aprovação de documento(s)",
  DELIVERED = "Aguardando liberação",
  DONE = "Aguardando finalização",
  COMMERCIALLY_APPROVED = "Aguardando aprovação comercial",
  CREDIT_APPROVED = "Aguardando aprovação de crédito",
  CLIENT_SIGNED = "Aguardando assinatura do cooperado",
  TIMED_OUT = "Expirado",
  IN_PROGRESS = "Em andamento",
  REJECTED = "Rejeitado",
  OPTIONAL_SIGNATURES_COLLECTED = "Aguardando assinaturas opcionais",
  PROCESSED = "Processado"
}

export enum ListDataMassActionsTypes {
  INVENTORY = "INVENTORY",
  OFFER = "OFFER",
  LINE = "LINE"
}
export interface PaginationMeta {
  currentPage: string;
  itemCount: number;
  itemsPerPage: string;
  totalItems: number;
  totalPages: number;
  contractTypes: [];
  contractTypesQty: [];
}

export enum ErrorMessagesMassActions {
  SELECT_ITEM_EMPTY = `Selecione no mínimo um item da lista para atualizar os valores.`,
  FIELD_VALUE_EMPTY = `Selecione no mínimo um CAMPO para atualizar o valor.`,
  GENERIC_ERROR = `Erro ao atualizar itens, contate o suporte`
}
export enum SuccessMessagesMassActions {
  UPDATE_SUCCESS = "Itens Atualizados com sucesso",
  OFFER_UPDATE_GENERIC_SUCCESS = "Pacotes de Benefícios atualizadas com sucesso"
}

export interface ItemsWithPagination {
  items: [];
  meta: PaginationMeta;
}

export type FiltersProps = {
  disabled?: boolean;
  heightItem?: string;
  label?: string;
  hideLabel?: boolean;
  placeholder?: string;
  multiLine?: boolean;
  type?: FiltersTypeEnum;
  maskFormatter?: (value: string) => string;
  valueFormatter?: (value: string) => string;
  customConditional?: (value: string) => boolean;
  onSelectedKeysChange?: (data: any) => void;

  maxLength?: number;
  minWidth?: string;
  maxWidth?: string;
  options?: {
    key: string | number;
    text: string;
    disabled?: boolean;
  }[];
  defaultValue?: string | number;
  resetFilterOnSelect?: string;
  filterQuery?: string | string[];
  massActionsType?: ListDataMassActionsTypes;
  /**
   * minDate string format: 'YYYY-MM-DD'
   *
   */
  minDate?: string;
  /**
   * maxDate string format: 'YYYY-MM-DD'
   *
   */
  maxDate?: string;
  inputType?: string;
  mask?: string;
  multiSelect?: boolean;
  isTagMode?: boolean;
  dialogTitle?: string;
  dialogSubText?: string;
  successDescription?: string;
  boxTitle?: string;
};

export type FilterComponentProps = {
  filterConfigs: FiltersProps;
  selectedKeys?: { [key: string]: any };
  debouncedTerms?: { [key: string]: string | number };
  selectedTerms?: { [key: string]: any };
  visibleTerms?: { [key: string]: string };
  itemsPerPage?: number;
  setSelectedKeys?: (keys) => void;
  setVisibleTerms?: (terms) => void;
  setSelectedTerms?: (terms) => void;
  _paginate?: (params) => void;
  mask?: string;
};

export type ListDataFiltersProps = {
  _paginate: (params) => void;
  setVisibleTerms: (terms) => void;
  setSelectedKeys: (keys) => void;
  setSelectedTerms: (terms) => void;
  toggleFilterVisibility: () => void;
  filterCurrentItems: (value, filterQuery) => void;
  items: any[];
  filters: FiltersProps[];
  itemsPerPage: number;
  filterTitle?: string;
  setItemsPerPage: (value: number) => void;
  selectedKeys: { [key: string]: any };
  debouncedTerms: { [key: string]: string | number };
  visibleTerms: { [key: string]: string };
  selectedTerms: { [key: string]: any };
  isItemsPerPageFilterDisabled: boolean;
  isCloseFilterButtonDisabled: boolean;
  massActionsType?: ListDataMassActionsTypes;
  selectedCounter?: number;
  sendMassActionsFunction?: (
    params:
      | any
      | {
          valueUpdated: string;
          byFilter: boolean;
          selectedKeys?: any;
          debouncedTerms?: any;
          itemsSelected?: any;
        }
  ) => Promise<
    | {
        response: {
          ok: boolean;
        };
        data: any;
        error?: undefined;
      }
    | {
        response: {
          ok: boolean;
        };
        error: {
          message: any;
          status: any;
          statusText: any;
        };
        data?: undefined;
      }
  >;
  selectedItemsIds?: Array<number>;
};
