import {
  IComboBox,
  IComboBoxOption,
  Stack,
  VirtualizedComboBox
} from "@fluentui/react";
import {
  FormEvent,
  ReactNode,
  UIEvent,
  useEffect,
  useRef,
  useState
} from "react";

import { MetaProps } from "@/common/types/ListFromApi.types";
import { LabelWithTooltip } from "@/components/LabelWithTooltip";
import { Loader } from "@/components/Shared/Loader";
import { useDebounce } from "@/hooks/index";

interface InputFromProps {
  dinamicSearchDisabled?: boolean;
  placeholder?: string;
  label: string;
  name: string;
  disabled?: boolean;
  required?: boolean;
  options?: { key: string; text: string }[];
  queryName?: string;
  requestFunction?: (params?) => void;
  requestMetaInfo?: MetaProps;
  isLoadingOptions?: boolean;
  multiSelect?: boolean;
  tooltipDescription?: string;
  executeOnChange?: (value: unknown) => void;
}

interface SearchSelectFieldProps {
  fieldConfig: InputFromProps;
  setValue?: (fieldName, fieldValue) => void;
  setState?: (fieldName) => void;
  errors: { [fieldName: string]: string };
  touched: { [fieldName: string]: string };
  defaultValue: string;
  selectedKeyParam?: any[];
  errorMessage?: string;
}

export function SearchSelectField({
  fieldConfig,
  setValue,
  errors,
  touched,
  defaultValue,
  selectedKeyParam,
  errorMessage
}: SearchSelectFieldProps): JSX.Element {
  const comboBoxRef = useRef<IComboBox>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [items, setItems] = useState<IComboBoxOption[]>([]);
  const [selectedKey, setSelectedKey] = useState<string | number>("");
  const [selectedTerms, setSelectedTerms] = useState<string>(null);
  const [selectedOption, setSelectedOption] = useState([]);

  const debouncedTerms: string = useDebounce(selectedTerms, 1000);
  const initialRender = useRef(true);
  useEffect(() => {
    if (defaultValue) {
      setSelectedOption([defaultValue]);
      setSelectedKey(defaultValue);
    }
    //eslint-disable-next-line
  }, [defaultValue]);

  useEffect(() => {
    if (fieldConfig.options) {
      setItems([...fieldConfig.options]);
    }
    //eslint-disable-next-line
  }, [fieldConfig.options]);

  useEffect(() => {
    if (
      fieldConfig.requestMetaInfo &&
      fieldConfig.requestMetaInfo.currentPage === 1 &&
      fieldConfig.requestMetaInfo.totalPages > 1 &&
      !fieldConfig.dinamicSearchDisabled
    ) {
      // if it's on page 1, load more items to enable scroll
      const queryParams = {
        page: fieldConfig.requestMetaInfo.currentPage + 1
      };

      if (debouncedTerms) {
        queryParams[fieldConfig.queryName] = debouncedTerms;
      }

      fieldConfig.requestFunction(queryParams);
    }
    //eslint-disable-next-line
  }, [fieldConfig.requestMetaInfo]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (!fieldConfig.dinamicSearchDisabled) {
      if (debouncedTerms) {
        fieldConfig.requestFunction({
          [fieldConfig.queryName]: debouncedTerms
        });
        setItems([]);
      } else {
        fieldConfig.requestFunction();
        setItems([]);
      }
    }
  }, [debouncedTerms]);

  function onChange(ev: FormEvent<IComboBox>, option?: IComboBoxOption): void {
    if (option.key === defaultValue) {
      setValue(fieldConfig.name, null);
    }

    if (fieldConfig.executeOnChange) {
      fieldConfig.executeOnChange({
        key: option.key,
        value: fieldConfig.name
      });
      setValue(fieldConfig.name, option.key);
    }

    if (option) {
      const selectedOptions = new Set(selectedOption);
      if (option.selected) {
        selectedOptions.add(option.key);
      } else {
        selectedOptions.delete(option.key);
      }
      setSelectedOption([...selectedOptions]);

      setSelectedKey(option.key);
      setValue(fieldConfig.name, option.key);
    }
  }

  function handleScroll(event: UIEvent<IComboBox>): void {
    if (!fieldConfig.dinamicSearchDisabled) {
      const targetElement = event.target as Element;

      const isScrollEnd =
        Math.ceil(targetElement.scrollHeight) -
          Math.ceil(targetElement.scrollTop) ===
        Math.ceil(targetElement.clientHeight);

      if (
        isScrollEnd &&
        !fieldConfig.isLoadingOptions &&
        fieldConfig.requestMetaInfo &&
        fieldConfig.requestMetaInfo.totalPages >
          fieldConfig.requestMetaInfo.currentPage
      ) {
        const queryParams = {
          page: fieldConfig.requestMetaInfo.currentPage + 1
        };

        if (debouncedTerms) {
          queryParams[fieldConfig.queryName] = debouncedTerms;
        }
        fieldConfig.requestFunction(queryParams);
      }
    }
  }

  return (
    <Stack styles={{ root: { position: "relative" } }}>
      <LabelWithTooltip
        description={fieldConfig.tooltipDescription}
        label={fieldConfig.label}
        required={fieldConfig.required || false}
      />
      <VirtualizedComboBox
        id={`input-${fieldConfig.name}`}
        componentRef={comboBoxRef}
        onScroll={handleScroll}
        onChange={onChange}
        multiSelect={fieldConfig.multiSelect}
        selectedKey={fieldConfig.multiSelect ? selectedKeyParam : selectedKey}
        defaultValue={defaultValue}
        allowFreeform
        autoComplete="on"
        options={
          selectedTerms && fieldConfig.dinamicSearchDisabled
            ? items.filter(i =>
                i?.text
                  ?.toLocaleLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .includes(
                    selectedTerms
                      .toLocaleLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                  )
              )
            : items
        }
        onMenuDismiss={() => {
          setSelectedTerms(null);
        }}
        styles={{
          optionsContainer: { maxHeight: "250px" }
        }}
        useComboBoxAsMenuWidth
        onPendingValueChanged={(_, __, value) => {
          if (value === "") {
            setSelectedKey("");
            setValue(fieldConfig.name, "");
            setSelectedTerms(null);
          }

          value && setSelectedTerms(value);
          comboBoxRef.current.focus(true);
        }}
        errorMessage={
          errorMessage ??
          (errors[fieldConfig?.name] && touched[fieldConfig?.name]
            ? errors[fieldConfig.name]
            : null)
        }
        placeholder={fieldConfig.placeholder || fieldConfig.label}
        disabled={fieldConfig.disabled || fieldConfig.isLoadingOptions || false}
        required={fieldConfig.required || false}
        autofill={{
          required: fieldConfig.required || false
        }}
        onClick={() => {
          if (fieldConfig.disabled) {
            return;
          }
          if (isFocused) {
            comboBoxRef.current.dismissMenu();
            setIsFocused(false);
          } else {
            comboBoxRef.current.focus(true);
            setIsFocused(true);
          }
        }}
        onRenderLowerContent={() => {
          if (fieldConfig.isLoadingOptions) {
            return (
              <Stack
                tokens={{ padding: 40 }}
                styles={{ root: { position: "relative" } }}
              >
                <Loader customMessage="Buscando opções" />
              </Stack>
            );
          }
        }}
        onBlur={() => isFocused && setIsFocused(false)}
        calloutProps={{ calloutMaxHeight: 500 }}
      />
    </Stack>
  );
}
