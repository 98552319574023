import { Badge } from "../../../../styles";

import {
  ContractBadgeColors,
  TranslatedContractCurrentStep
} from "@/modules/Contracts/enums";
import { ContractItem } from "@/modules/Contracts/types/types";
interface NextStepTabProps {
  item: ContractItem;
}

export function NextStepCell({ item }: NextStepTabProps) {
  return (
    <Badge
      bgColor="transparent"
      display="flex"
      flexGrow="1"
      justifyContent="center"
      wordWrap="break-word"
      whiteSpace="unset"
      fontWeight="600"
      fontColor={
        ContractBadgeColors[item?.currentStep?.internalName] ??
        ContractBadgeColors.DEFAULT
      }
      border={`1px solid ${
        ContractBadgeColors[item?.currentStep?.internalName] ??
        ContractBadgeColors.DEFAULT
      }`}
    >
      {item.nextStep?.friendlyName ??
        TranslatedContractCurrentStep[item?.currentStep?.internalName]}
    </Badge>
  );
}
