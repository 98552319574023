import { DefaultButton, Icon, Stack } from "@fluentui/react";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

import { DefaultCardTitle } from "../styles";

import {
  AccountType,
  TranslatedContactType,
  TranslatedMaritalStatus
} from "./types";

import { Card } from "@/components/Shared/Card";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";
import { formatISOStringToDateString } from "@/utils/FormatISOStringToDateString";

interface ContractInfoProps {
  openDrawer?: () => void;
  contract: any;
  title: string;
  isInsidePanel: boolean;
}

interface ContractDataItemProps {
  title: string;
  value: string | ReactNode;
}

const tokens = {
  sectionStack: {
    childrenGap: 10,
    padding: 20
  },
  headingStack: {
    childrenGap: 10
  }
};

export function ClientInfo({
  contract,
  title,
  openDrawer,
  isInsidePanel
}: ContractInfoProps): JSX.Element {
  return (
    <Stack verticalFill>
      <Stack
        horizontal
        className="ms-sm12"
        tokens={{ childrenGap: 10 }}
        style={{ marginBottom: "1vh", height: 35 }}
      >
        <DefaultCardTitle>
          <Icon iconName="Contact" style={{ marginRight: 10 }} />
          {title}
        </DefaultCardTitle>

        {openDrawer ? (
          <DefaultButton id="view-client-button" onClick={openDrawer}>
            Informações
          </DefaultButton>
        ) : null}
      </Stack>

      <Card borderRadius="1rem" padding="1rem">
        <Stack horizontal wrap tokens={tokens.sectionStack}>
          <Stack.Item
            grow
            className={`ms-sm12 ${!isInsidePanel ? "ms-lg5" : ""}`}
          >
            <Stack tokens={tokens.headingStack}>
              <ContractInfoCamp
                title="Nome:"
                value={contract.account.fullName}
              />
              {contract.account.accountType !== AccountType.LEGAL && (
                <>
                  {contract.account.birthDate && (
                    <ContractInfoCamp
                      title="Data de Nascimento:"
                      value={formatISOStringToDateString(
                        contract.account.birthDate
                      )}
                    />
                  )}
                  <ContractInfoCamp
                    title="Estado Civil:"
                    value={
                      TranslatedMaritalStatus[
                        contract.account.maritalStatus?.name
                      ]
                    }
                  />
                  <ContractInfoCamp
                    title="Ocupação:"
                    value={contract.account.occupation}
                  />
                  <ContractInfoCamp
                    title={
                      TranslatedContactType[
                        contract.accountContact.contactType
                      ] + ":"
                    }
                    value={contract.accountContact.contact}
                  />
                </>
              )}
            </Stack>
          </Stack.Item>

          <Stack.Item
            grow
            className={`ms-sm12 ${!isInsidePanel ? "ms-lg5" : ""}`}
          >
            <Stack tokens={tokens.headingStack}>
              {contract.account.document?.documentType === "RG" && (
                <ContractInfoCamp
                  key={contract.account.document.id}
                  title={contract.account.document.documentType + ":"}
                  value={cpfCnpjFormatter(
                    contract.account.document.documentNumber
                  )}
                />
              )}
              {contract.account.secondaryDocument && (
                <ContractInfoCamp
                  key={contract.account.secondaryDocument.id}
                  title={contract.account.secondaryDocument.documentType + ":"}
                  value={contract.account.secondaryDocument.documentNumber}
                />
              )}
              <ContractInfoCamp
                title={contract.accountDocument.documentType + ":"}
                value={cpfCnpjFormatter(
                  contract.accountDocument.documentNumber
                )}
              />
              <ContractInfoCamp
                title="Endereço:"
                value={
                  <p>
                    {contract.accountAddress.city}{" "}
                    {contract.accountAddress.state
                      ? `- ${contract.accountAddress.state}`
                      : ""}{" "}
                    <br />
                    {contract.accountAddress.street},{" "}
                    {contract.accountAddress.number ?? ""}{" "}
                    {contract.accountAddress.note
                      ? " - " + contract.accountAddress.note
                      : ""}{" "}
                    {contract.accountAddress.neighborhood
                      ? " - " + contract.accountAddress.neighborhood
                      : ""}
                    , {contract.accountAddress.state} - CEP{" "}
                    {contract.accountAddress.zipCode.substring(0, 5) +
                      "-" +
                      contract.accountAddress.zipCode.substring(5)}
                  </p>
                }
              />
              {contract.checkingAccount && (
                <div
                  style={{
                    lineHeight: 1.2
                  }}
                >
                  <div
                    className="ms-fontWeight-semibold"
                    style={{ width: "280px" }}
                  >
                    Dados Bancários
                  </div>
                  <div>{`Banco ${contract.checkingAccount?.bankNumber}`}</div>
                  <div>{`Agência ${contract.checkingAccount.agencyNumber}`}</div>
                  <div>{`C/C ${contract.checkingAccount.accountNumber}`}</div>
                </div>
              )}
            </Stack>
          </Stack.Item>
        </Stack>
      </Card>
    </Stack>
  );
}

export function ContractInfoCamp({
  title,
  value
}: ContractDataItemProps): JSX.Element {
  return (
    <div
      style={{
        lineHeight: 1.2,
        overflowWrap: "break-word"
      }}
    >
      <div className="ms-fontWeight-semibold" style={{ width: "280px" }}>
        {title}
      </div>

      <div>{value}</div>
    </div>
  );
}
