import {
  Checkbox,
  DetailsList,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SelectionMode
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FiltersTypeEnum } from "../enums";
import {
  CheckboxFilter,
  DatePicker,
  MaskedText,
  SelectFilter,
  TextArrayFilter,
  TextFilter
} from "../Filters";

import {
  buildModalColumns,
  IMassActionFields,
  inventoryMassActionFields,
  linesActionFields,
  offersMassActionFields,
  populateModalColumns
} from "./mass-actions.fields";
import {
  MassActionContainer,
  MassActionContent,
  MassActionFieldsContent,
  MassActionPassContent
} from "./styles";

import { ListDataFilters } from "@/core/libs/list-data/lib/ListDataFilters";
import {
  ErrorMessagesMassActions,
  ListDataFiltersProps,
  ListDataMassActionsTypes,
  SuccessMessagesMassActions
} from "@/core/libs/list-data/lib/types";
import { RootState } from "@/core/store/rootReducer";
import { useConfirmModal } from "@/hooks/useConfirmModal";
import { listRequest } from "@/modules/Settings/actions/inventories";
import { listLinesRequest } from "@/modules/Settings/actions/lines";
import { isObjectEmpty } from "@/utils/IsObjectEmpty";

export interface ListDataFiltersMassActionProps extends ListDataFiltersProps {
  openMassActionsFilter: boolean;
  itemsSelection?: Array<any>;
  setItemsSelected?: React.Dispatch<React.SetStateAction<any[]>>;
  setBulkOptionsFilters?: (filters: object) => void;
}

export const ListDataFiltersMassAction = ({
  _paginate,
  setItemsPerPage,
  setVisibleTerms,
  setSelectedKeys,
  setSelectedTerms,
  filterCurrentItems,
  toggleFilterVisibility,
  items,
  filters,
  visibleTerms,
  selectedKeys,
  itemsPerPage,
  selectedTerms,
  debouncedTerms,
  isItemsPerPageFilterDisabled,
  massActionsType,
  selectedCounter,
  sendMassActionsFunction,
  selectedItemsIds,
  openMassActionsFilter,
  itemsSelection,
  setItemsSelected,
  setBulkOptionsFilters
}: ListDataFiltersMassActionProps) => {
  const {
    distributionCenters: { list: distributionCenterList }
  } = useSelector((store: RootState) => store);

  const {
    carriers: { list: carrierList }
  } = useSelector((store: RootState) => store);

  const dispatch = useDispatch();
  const { openModalWithSettings } = useConfirmModal();
  const [selectedFields, setSelectedFields] = useState({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [fieldsToUpdate, setFieldsToUpdate] = useState<
    Array<IMassActionFields>
  >([] as Array<IMassActionFields>);

  const [distributionCenterSelection, setDistributionCenterSelection] =
    useState(undefined);

  const [carrierSelection, setCarrierSelection] = useState(undefined);

  const filterActionSwitch = useCallback(() => {
    if (massActionsType) {
      return filters.map(filter => {
        switch (massActionsType) {
          case ListDataMassActionsTypes.OFFER:
            if (filter.filterQuery === "pricingGroup") {
              return {
                ...filter,
                multiSelect: true,
                label: filter.label + " (Obrigatório)"
              };
            }

            return filter;
          default:
            return filter;
        }
      });
    } else {
      return filters;
    }
  }, [filters, massActionsType]);

  const MassUpdateGenerateFields = useCallback(() => {
    switch (massActionsType) {
      case ListDataMassActionsTypes.INVENTORY:
      case ListDataMassActionsTypes.LINE:
        return fieldsToUpdate.map(field => {
          return fieldsBuilder(field);
        });
      case ListDataMassActionsTypes.OFFER:
        return fieldsToUpdate.map(field => {
          return fieldsBuilder(field);
        });
    }
  }, [fieldsToUpdate, massActionsType, selectedFields]);

  const fieldsBuilder = useCallback(
    (fieldsOptions: IMassActionFields) => {
      switch (fieldsOptions.type) {
        case FiltersTypeEnum.SELECT: {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center"
              }}
            >
              <Checkbox
                label=""
                checked={
                  !fieldsToUpdate.find(f => f.key === fieldsOptions.key)
                    .disabled
                    ? true
                    : false
                }
                onChange={(e, checked) => {
                  if (fieldsOptions.field === "lineStatus") {
                    if (checked) {
                      setBulkOptionsFilters({ isLineStatus: true });
                    } else {
                      setBulkOptionsFilters({ isLineStatus: false });
                    }
                  }
                  if (!checked) {
                    setSelectedFields(prevSelectedFields => {
                      delete prevSelectedFields[fieldsOptions.field];
                      return prevSelectedFields;
                    });
                  }
                  setFieldsToUpdate(prevFields =>
                    prevFields.map(field => {
                      if (field.key === fieldsOptions.key) {
                        return {
                          ...field,
                          disabled: !checked
                        };
                      }
                      return field;
                    })
                  );
                }}
                styles={{
                  checkbox: { marginBottom: "6px" }
                }}
              />
              <SelectFilter
                key={`${fieldsOptions.fieldName}`}
                filterConfigs={{
                  filterQuery: fieldsOptions.field,
                  label: fieldsOptions.fieldName,
                  placeholder: fieldsOptions.fieldName,
                  options: fieldsOptions?.options,
                  disabled: fieldsOptions.disabled
                }}
                debouncedTerms={selectedFields}
                selectedKeys={selectedFields}
                itemsPerPage={itemsPerPage}
                _paginate={() => {
                  return;
                }}
                setSelectedKeys={setSelectedFields}
              />
            </div>
          );
        }

        case FiltersTypeEnum.DATE: {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center"
              }}
            >
              <Checkbox
                label=""
                checked={
                  !fieldsToUpdate.find(f => f.key === fieldsOptions.key)
                    .disabled
                    ? true
                    : false
                }
                onChange={(e, checked) => {
                  if (!checked) {
                    setSelectedFields(prevSelectedFields => {
                      delete prevSelectedFields[fieldsOptions.field];
                      return prevSelectedFields;
                    });
                  }
                  setFieldsToUpdate(prevFields =>
                    prevFields.map(field => {
                      if (field.key === fieldsOptions.key) {
                        return {
                          ...field,
                          disabled: !checked
                        };
                      }
                      return field;
                    })
                  );
                }}
                styles={{
                  checkbox: { marginBottom: "6px" }
                }}
              />
              <DatePicker
                key={`${fieldsOptions.fieldName}`}
                filterConfigs={{
                  filterQuery: fieldsOptions.field,
                  label: fieldsOptions.fieldName,
                  placeholder: fieldsOptions.fieldName,
                  disabled: fieldsOptions.disabled
                }}
                debouncedTerms={selectedFields}
                selectedKeys={selectedFields}
                itemsPerPage={itemsPerPage}
                _paginate={() => {
                  return;
                }}
                setSelectedKeys={value => {
                  setSelectedFields(value);
                }}
              />
            </div>
          );
        }

        case FiltersTypeEnum.CHECKBOX: {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center"
              }}
            >
              <Checkbox
                label=""
                checked={
                  !fieldsToUpdate.find(f => f.key === fieldsOptions.key)
                    .disabled
                    ? true
                    : false
                }
                onChange={(e, checked) => {
                  if (!checked) {
                    setSelectedFields(prevSelectedFields => {
                      delete prevSelectedFields[fieldsOptions.field];
                      return prevSelectedFields;
                    });
                  }
                  setFieldsToUpdate(prevFields =>
                    prevFields.map(field => {
                      if (field.key === fieldsOptions.key) {
                        return {
                          ...field,
                          disabled: !checked
                        };
                      }
                      return field;
                    })
                  );
                }}
                styles={{
                  checkbox: { marginBottom: "6px" }
                }}
              />
              <CheckboxFilter
                key={`${fieldsOptions.fieldName}`}
                filterConfigs={{
                  filterQuery: fieldsOptions.field,
                  label: fieldsOptions.fieldName,
                  placeholder: fieldsOptions.fieldName,
                  disabled: fieldsOptions.disabled
                }}
                selectedKeys={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                debouncedTerms={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                itemsPerPage={itemsPerPage}
                setSelectedKeys={setSelectedFields}
                _paginate={() => {
                  return;
                }}
              />
            </div>
          );
        }

        case FiltersTypeEnum.MASK_TEXT: {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center"
              }}
            >
              <Checkbox
                label=""
                checked={
                  !fieldsToUpdate.find(f => f.key === fieldsOptions.key)
                    .disabled
                    ? true
                    : false
                }
                onChange={(e, checked) => {
                  if (!checked) {
                    setSelectedFields(prevSelectedFields => {
                      delete prevSelectedFields[fieldsOptions.field];
                      return prevSelectedFields;
                    });
                  }
                  setFieldsToUpdate(prevFields =>
                    prevFields.map(field => {
                      if (field.key === fieldsOptions.key) {
                        return {
                          ...field,
                          disabled: !checked
                        };
                      }
                      return field;
                    })
                  );
                }}
                styles={{
                  checkbox: { marginBottom: "6px" }
                }}
              />
              <MaskedText
                key={`${fieldsOptions.fieldName}`}
                filterConfigs={{
                  filterQuery: fieldsOptions.field,
                  label: fieldsOptions.fieldName,
                  placeholder: fieldsOptions.fieldName,
                  disabled: fieldsOptions.disabled
                }}
                selectedTerms={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                visibleTerms={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                setVisibleTerms={setSelectedFields}
                setSelectedTerms={setSelectedFields}
              />
            </div>
          );
        }

        case FiltersTypeEnum.ARRAY_TEXT: {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center"
              }}
            >
              <Checkbox
                label=""
                checked={
                  !fieldsToUpdate.find(f => f.key === fieldsOptions.key)
                    .disabled
                    ? true
                    : false
                }
                onChange={(e, checked) => {
                  if (!checked) {
                    setSelectedFields(prevSelectedFields => {
                      delete prevSelectedFields[fieldsOptions.field];
                      return prevSelectedFields;
                    });
                  }
                  setFieldsToUpdate(prevFields =>
                    prevFields.map(field => {
                      if (field.key === fieldsOptions.key) {
                        return {
                          ...field,
                          disabled: !checked
                        };
                      }
                      return field;
                    })
                  );
                }}
                styles={{
                  checkbox: { marginBottom: "6px" }
                }}
              />
              <TextArrayFilter
                key={`${fieldsOptions.fieldName}`}
                filterConfigs={{
                  filterQuery: fieldsOptions.field,
                  label: fieldsOptions.fieldName,
                  placeholder: fieldsOptions.fieldName,
                  disabled: fieldsOptions.disabled
                }}
                selectedTerms={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                visibleTerms={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                setVisibleTerms={setSelectedFields}
                setSelectedTerms={setSelectedFields}
              />
            </div>
          );
        }

        default: {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                justifyContent: "center"
              }}
            >
              <Checkbox
                label=""
                checked={
                  !fieldsToUpdate.find(f => f.key === fieldsOptions.key)
                    .disabled
                    ? true
                    : false
                }
                onChange={(e, checked) => {
                  if (!checked) {
                    setSelectedFields(prevSelectedFields => {
                      delete prevSelectedFields[fieldsOptions.field];
                      return prevSelectedFields;
                    });
                  }
                  setFieldsToUpdate(prevFields =>
                    prevFields.map(field => {
                      if (field.key === fieldsOptions.key) {
                        return {
                          ...field,
                          disabled: !checked
                        };
                      }
                      return field;
                    })
                  );
                }}
                styles={{
                  checkbox: { marginBottom: "6px" }
                }}
              />
              <TextFilter
                key={`${fieldsOptions.fieldName}`}
                filterConfigs={{
                  filterQuery: fieldsOptions.field,
                  label: fieldsOptions.fieldName,
                  placeholder: fieldsOptions.fieldName,
                  disabled: fieldsOptions.disabled
                }}
                selectedTerms={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                visibleTerms={
                  selectedFields[fieldsOptions.fieldName]
                    ? selectedFields[fieldsOptions.fieldName]
                    : {}
                }
                setVisibleTerms={setSelectedFields}
                setSelectedTerms={setSelectedFields}
              />
            </div>
          );
        }
      }
    },
    [fieldsToUpdate, selectedFields, itemsPerPage, setSelectedFields]
  );

  const resetFilters = () => {
    setVisibleTerms({});
    setSelectedKeys({});
    setSelectedFields({});
    setSelectedTerms({});
    setItemsSelected && setItemsSelected([]);
    switch (massActionsType) {
      case ListDataMassActionsTypes.INVENTORY:
        setFieldsToUpdate(
          inventoryMassActionFields({
            options: { distributionCenterOptions: distributionCenterSelection }
          })
        );
        break;
      case ListDataMassActionsTypes.LINE:
        setFieldsToUpdate(
          linesActionFields({
            options: { carrierOptions: carrierSelection }
          })
        );
        break;
      case ListDataMassActionsTypes.OFFER:
        setFieldsToUpdate(offersMassActionFields);
        break;
      default:
        break;
    }
  };

  const columnPopulatedFinderTranslator = useCallback(
    (data: Array<any>) => {
      return data.map(i => {
        if (
          massActionsType === ListDataMassActionsTypes.INVENTORY &&
          i["distributionCenter"] &&
          i["new-distributionCenter"]
        ) {
          return {
            ...i,
            distributionCenter: distributionCenterSelection?.find(
              dc => dc.key === i["distributionCenter"]
            ).text,
            "new-distributionCenter": distributionCenterSelection?.find(
              dc => dc.key === i["new-distributionCenter"]
            ).text
          };
        } else if (
          massActionsType === ListDataMassActionsTypes.LINE &&
          i["new-carrier"]
        ) {
          return {
            ...i,
            "new-carrier": carrierSelection.find(
              c => c.key === i["new-carrier"]
            ).text
          };
        } else {
          return i;
        }
      });
    },
    [distributionCenterSelection, massActionsType, carrierSelection]
  );

  useEffect(() => {
    if (!openMassActionsFilter) {
      resetFilters();
    }
    if (openMassActionsFilter) {
      switch (massActionsType) {
        case ListDataMassActionsTypes.INVENTORY:
          dispatch(listRequest({ massActions: true }, { isAdmin: true }));
          break;
        case ListDataMassActionsTypes.LINE:
          dispatch(listLinesRequest({ massActions: true }, { isAdmin: true }));
          break;
        case ListDataMassActionsTypes.OFFER:
          setFieldsToUpdate(offersMassActionFields);
          break;
        default:
          break;
      }
    }
  }, [openMassActionsFilter]);

  useEffect(() => {
    if (
      distributionCenterSelection === undefined &&
      distributionCenterList?.items
    ) {
      setDistributionCenterSelection(
        distributionCenterList?.items.map(item => {
          return { key: item.id, text: item.name };
        })
      );
    }
  }, [distributionCenterList, distributionCenterSelection]);

  useEffect(() => {
    if (carrierSelection === undefined && carrierList?.items) {
      setCarrierSelection(
        carrierList?.items.map(item => {
          return { key: item?.id, text: item?.name };
        })
      );
    }
  }, [carrierSelection, carrierList]);

  useEffect(() => {
    switch (massActionsType) {
      case ListDataMassActionsTypes.INVENTORY:
        setFieldsToUpdate(
          inventoryMassActionFields({
            options: { distributionCenterOptions: distributionCenterSelection }
          })
        );
        break;
      case ListDataMassActionsTypes.LINE:
        setFieldsToUpdate(
          linesActionFields({
            options: { carrierOptions: carrierSelection }
          })
        );
        break;
      case ListDataMassActionsTypes.OFFER:
        setFieldsToUpdate(offersMassActionFields);
        break;
      default:
        break;
    }
  }, [distributionCenterSelection, massActionsType, carrierSelection]);

  return (
    <>
      {openMassActionsFilter && (
        <MassActionContainer>
          <h2>Modo de Atualização em Massa</h2>
          <MassActionContent>
            <ListDataFilters
              setItemsPerPage={setItemsPerPage}
              setSelectedKeys={setSelectedKeys}
              setVisibleTerms={setVisibleTerms}
              setSelectedTerms={setSelectedTerms}
              filterCurrentItems={filterCurrentItems}
              toggleFilterVisibility={toggleFilterVisibility}
              items={items}
              filters={filterActionSwitch()}
              _paginate={_paginate}
              itemsPerPage={itemsPerPage}
              visibleTerms={visibleTerms}
              selectedKeys={selectedKeys}
              selectedTerms={selectedTerms}
              debouncedTerms={debouncedTerms}
              isCloseFilterButtonDisabled={true}
              isItemsPerPageFilterDisabled={isItemsPerPageFilterDisabled}
              selectedCounter={selectedCounter}
              massActionsType={massActionsType ?? null}
              sendMassActionsFunction={sendMassActionsFunction}
              filterTitle={"Selecione os filtros para atualização (opcional)"}
            />
            <MassActionPassContent>
              <div />
            </MassActionPassContent>

            <MassActionFieldsContent>
              <h3>Adicione o valor dos campos a serem atualizados</h3>
              <div>
                <div>
                  {MassUpdateGenerateFields && MassUpdateGenerateFields()}
                </div>
                <div>
                  <PrimaryButton
                    text={`Atualizar Somente os ${
                      selectedItemsIds?.length ?? 0
                    } itens Selecionados`}
                    onClick={() => {
                      setSuccessMessage(undefined);
                      setErrorMessage(undefined);
                      const itemsSelected = selectedItemsIds ?? [];
                      if (itemsSelected?.length === 0) {
                        return setErrorMessage(
                          ErrorMessagesMassActions.SELECT_ITEM_EMPTY
                        );
                      }
                      if (isObjectEmpty(selectedFields)) {
                        return setErrorMessage(
                          ErrorMessagesMassActions.FIELD_VALUE_EMPTY
                        );
                      }

                      const modalMainText = `Deseja atualizar ${
                        itemsSelected?.length === 1
                          ? "o item selecionado"
                          : `${itemsSelected?.length ?? 0} itens selecionados`
                      }?`;

                      openModalWithSettings(
                        modalMainText,
                        "Ao atualizar, todos os itens escolhidos terão os seguintes valores alterados:",
                        async () => {
                          const response = await sendMassActionsFunction({
                            updatedFields: selectedFields,
                            byFilter: false,
                            selectedKeys,
                            debouncedTerms,
                            itemsSelected: itemsSelected
                          });
                          if (response?.error) {
                            return setErrorMessage(response?.error.message);
                          }
                          if (response.response.ok) {
                            setSuccessMessage(
                              SuccessMessagesMassActions.UPDATE_SUCCESS
                            );
                            resetFilters();
                          }
                        },
                        {
                          customComponent: () => {
                            return (
                              <DetailsList
                                columns={buildModalColumns(
                                  selectedFields,
                                  massActionsType
                                )}
                                selectionMode={SelectionMode.none}
                                items={
                                  itemsSelection &&
                                  itemsSelection[0] &&
                                  columnPopulatedFinderTranslator(
                                    // eslint-disable-next-line react/prop-types
                                    itemsSelection?.map(i =>
                                      populateModalColumns(
                                        i,
                                        selectedFields,
                                        massActionsType
                                      )
                                    )
                                  )
                                }
                                styles={{
                                  contentWrapper: {
                                    width: "fit-content",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    overflowX: "auto"
                                  }
                                }}
                              />
                            );
                          }
                        }
                      );
                    }}
                  />

                  <PrimaryButton
                    text={`Atualizar Todos os ${
                      items && items["meta"] && items["meta"].totalItems
                    } itens filtrados`}
                    onClick={() => {
                      setSuccessMessage(undefined);
                      setErrorMessage(undefined);
                      if (isObjectEmpty(selectedFields)) {
                        return setErrorMessage(
                          ErrorMessagesMassActions.FIELD_VALUE_EMPTY
                        );
                      }
                      openModalWithSettings(
                        `Deseja atualizar os ${items["meta"].totalItems} itens FILTRADOS?`,
                        "Ao atualizar, todos os itens escolhidos terão seguintes valores alterados.",
                        async () => {
                          const res = await sendMassActionsFunction({
                            updatedFields: selectedFields,
                            byFilter: true,
                            selectedKeys,
                            debouncedTerms
                          });
                          if (res?.error) {
                            return setErrorMessage(res?.error.message);
                          }
                          if (res.response.ok) {
                            setSuccessMessage(
                              SuccessMessagesMassActions.UPDATE_SUCCESS
                            );
                            resetFilters();
                          }
                        }
                      );
                    }}
                  />
                </div>
              </div>
            </MassActionFieldsContent>
            {!!errorMessage && (
              <MessageBar messageBarType={MessageBarType.error} isMultiline>
                {errorMessage}
              </MessageBar>
            )}
            {!!successMessage && (
              <MessageBar messageBarType={MessageBarType.success} isMultiline>
                {successMessage}
              </MessageBar>
            )}
          </MassActionContent>
        </MassActionContainer>
      )}
    </>
  );
};
