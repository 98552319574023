import { useHistory } from "react-router-dom";

import { ContractBadgeColors } from "@/modules/Contracts/enums";
import {
  Badge,
  ClickableDiv,
  DocumentInfoSpan
} from "@/modules/Contracts/styles";
import { ContractItem } from "@/modules/Contracts/types/types";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";

interface AccountTabProps {
  canRead: boolean;
  item: ContractItem;
  isInProgress: boolean;
}

export function AccountCell({ canRead, item, isInProgress }: AccountTabProps) {
  const history = useHistory();

  const getContractPath = (id: number, isInProgress: boolean) =>
    `/contracts/${id}${isInProgress ? "/edit" : ""}`;

  const documentInfoSpanText =
    item?.accountDocument?.documentType +
    ": " +
    cpfCnpjFormatter(item?.accountDocument?.documentNumber);

  return (
    <ClickableDiv
      onClick={() =>
        canRead ? history.push(getContractPath(item.id, isInProgress)) : null
      }
      width="auto"
    >
      {item.account?.fullName ?? (
        <Badge
          bgColor="transparent"
          fontWeight="600"
          fontColor={ContractBadgeColors.DISABLED}
          border={`1px solid ${ContractBadgeColors.DISABLED}`}
        >
          Nenhum cooperado selecionado
        </Badge>
      )}
      {item.accountDocument ? (
        <DocumentInfoSpan>{documentInfoSpanText}</DocumentInfoSpan>
      ) : null}
    </ClickableDiv>
  );
}
