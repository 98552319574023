import { Route, Switch } from "react-router-dom";

import { useGetProfile } from "../libs/api/react-query";

import PrivateRoute from "./PrivateRoute";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import Contracts from "@/modules/Contracts";
import ContractsCreate from "@/modules/Contracts/pages/Create";
import { ContractsEdit } from "@/modules/Contracts/pages/Edit";
import { ContractView } from "@/modules/Contracts/pages/View";

function ContractsRoutes(): JSX.Element {
  const userProfile = useGetProfile();
  const permissions = userProfile.data?.data?.role.permissions;

  return (
    <Switch>
      <PrivateRoute
        exact
        path="/contracts"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.CONTRACTS}
        action={PermissionsAction.LIST}
      >
        <Contracts
          canCreate={permissions[PermissionsScope.POS][
            PermissionsPosModule.CONTRACTS
          ]?.some(
            action =>
              action === PermissionsAction.ALL ||
              action === PermissionsAction.CREATE
          )}
          canRead={permissions[PermissionsScope.POS][
            PermissionsPosModule.CONTRACTS
          ]?.some(
            action =>
              action === PermissionsAction.ALL ||
              action === PermissionsAction.READ
          )}
        />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/contracts/:id/edit"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.CONTRACTS}
        action={PermissionsAction.UPDATE}
      >
        <Route exact path="/contracts/:id/edit" component={ContractsEdit} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/contracts/create"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.CONTRACTS}
        action={PermissionsAction.CREATE}
      >
        <ContractsCreate />
      </PrivateRoute>
      <PrivateRoute
        path="/contracts/:id"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.CONTRACTS}
        action={PermissionsAction.READ}
      >
        <Route path="/contracts/:id" component={ContractView} />
      </PrivateRoute>
      <PrivateRoute
        path="/contracts/:id/occurrence"
        permissionScope={permissions[PermissionsScope.POS]}
        module={PermissionsPosModule.CONTRACTS}
        action="contract-occurrences"
      >
        <Route path="/contracts/:id/occurrence" component={ContractView} />
      </PrivateRoute>
    </Switch>
  );
}

export default ContractsRoutes;
