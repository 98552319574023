import {
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  Panel,
  PanelType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  Text,
  getTheme,
  Icon,
  DefaultButton
} from "@fluentui/react";
import { secondsToMilliseconds } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";

import {
  PaymentRequest,
  PaymentRequestStatus,
  PaymentRequestTypeProviders
} from "@/common/types/PaymentRequestTypes";
import ContractOccurrences from "@/components/Contracts/ContractOccurrences";
import ContractProductsRenewalModal from "@/components/Contracts/ContractProductsRenewal/ContractProductsRenewalModal";
import { DisplayContractInfo } from "@/components/Contracts/DisplayContractInfo";
import { ViewClient } from "@/components/Contracts/DisplayContractInfo/ViewClient";
import { UploadDocumentModal } from "@/components/Contracts/UploadDocumentModal";
import { ViewMenu } from "@/components/Contracts/ViewMenu";
import { ViewProgress } from "@/components/Contracts/ViewProgress";
import {
  CustomDrawer,
  DrawerActions,
  DrawerModules
} from "@/components/Shared/CustomDrawer";
import { Loader } from "@/components/Shared/Loader";
import { useGetPaymentConditions } from "@/core/libs/api/react-query/hook";
import { TextFilter } from "@/core/libs/list-data/lib/Filters";
import {
  MessageBar as MessageBarCustom,
  messageBarTypes
} from "@/core/libs/message-bar";
import { useConfirmModal } from "@/hooks/useConfirmModal";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { changeContractStatus, getContract } from "@/modules/Contracts/api";
import { sleep } from "@/utils/Sleep";

interface ContractViewProps {
  match: any;
  user?: any;
}

interface NextStep {
  friendlyName: string;
  internalName: string;
  order: number;
}

const CancelOrRejectJustificationContent = ({
  setValue,
  paymentRequests
}: {
  setValue: (value) => void;
  paymentRequests?: PaymentRequest[];
}) => {
  const [status, setStatus] = useState<{
    [key: string]: string;
  }>({});
  const hasCompletedPaymentRequest = paymentRequests?.some(
    paymentRequest =>
      paymentRequest.requestStatus === PaymentRequestStatus.COMPLETED &&
      paymentRequest.typeProvider === PaymentRequestTypeProviders.PIX
  );
  const theme = getTheme();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "400px"
      }}
    >
      <TextFilter
        filterConfigs={{
          label: "Motivo do Cancelamento",
          filterQuery: "cancel_reason",
          placeholder: "Motivo do Cancelamento",
          multiLine: true
        }}
        _paginate={() => {
          return;
        }}
        setVisibleTerms={value => {
          setStatus(value(""));
          setValue(value(""));
        }}
        debouncedTerms={status}
        visibleTerms={status}
        setSelectedTerms={value => {
          setStatus(value(""));
          setValue(value(""));
        }}
        selectedTerms={status}
      />
      {hasCompletedPaymentRequest && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            marginTop: "15px",
            columnGap: "7px"
          }}
        >
          <Icon
            iconName="IncidentTriangle"
            style={{ fontSize: "15px", color: theme.palette.yellowDark }}
          />
          <p
            style={{
              color: theme.palette.yellowDark,
              fontWeight: "600",
              fontSize: "13px",
              marginBottom: "0"
            }}
          >
            Ao realizar essa operação ocorrerá estorno de pagamento
          </p>
        </div>
      )}
    </div>
  );
};

export function ContractView(props: ContractViewProps): JSX.Element {
  const isReloading = useRef(false);
  const { openModalWithSettings } = useConfirmModal();

  const drawerContainerRef = useRef<HTMLDivElement>(null);

  const [statusChangeReason, setStatusChangeReason] = useState<string>("");
  const [activeStatusChangeRequest, setActiveStatusChangeReason] =
    useState<boolean>(false);
  const [action, setAction] = useState<string>(null);
  const [actionMessage, setActionMessage] = useState<string>(null);
  const [message, setMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [nextStep, setNextStep] = useState<NextStep>();
  const [contract, setContract] = useState(undefined);
  const [title, setTitle] = useState("Contrato - Libertas");
  const [isVisibleDocumentModal, setIsVisibleDocumentModal] =
    useState<boolean>(false);
  const [isProductRenewalModalOpen, setIsProductRenewalModalOpen] =
    useState(false);
  const [uploads, setUploads] = useState([]);
  const [
    isContractOccurrencesDrawerVisible,
    setIsContractOccurrencesDrawerVisible
  ] = useState(false);
  const [isVisibleActionModal, setIsVisibleActionModal] = useState(false);
  const [drawerState, setDrawerState] = useState({
    action: null,
    isOpen: false
  });

  const { selectedBU } = useSelectedBu();

  const [requestRetryCount, setRequestRetryCount] = useState<number>(1);

  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const actionConfirmText = {
    "approve-credit": selectedBU?.settings?.creditApprovalMessage
  };

  const cleanMessage = () => {
    setMessage(undefined);
  };

  const contractId = props.match.params.id;

  const { data: invoiceInfos } = useGetPaymentConditions({ contractId });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestContract = async () => {
    const response = await getContract(contractId);
    if (response.error) {
      openModalWithSettings(
        response.error.message,
        "Você será redirecionado para pagina de contratos",
        () => {
          history.push("/contracts");
          return;
        },
        { timeOutModeProps: { timeOut: secondsToMilliseconds(2) } }
      );
    }
    return response;
  };

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const response = await requestContract();

    if (response.data.statusCode === 404) {
      history.push("/contracts");
      setMessage({
        message: response.error.message ?? "Contrato não encontrado.",
        type: messageBarTypes.ERROR
      });
    }

    if (response.error) {
      setMessage({
        message:
          response.error.message ??
          "Não foi possível localizar o contrato, por favor atualize a página e tente novamente",
        type: messageBarTypes.ERROR
      });
    } else {
      const { data } = response;
      if (data.approvalFlow.steps) {
        setSteps(data.approvalFlow.steps);
      }
      // Can view only if !== IN_PROGRESS
      if (data.currentStep.internalName === "IN_PROGRESS") {
        history.push(`/contracts/${data.id}/edit`);
      } else {
        data.approvalFlow.steps.forEach(step => {
          if (step.internalName === data.currentStep.internalName) {
            setCurrentStep(step.order);
          }
        });

        if (data.uploads && data.uploads.length > 0) {
          setUploads(data.uploads);
        }

        setNextStep(data.nextStep);
        setContract(data);
        setTitle(`Contrato #${data.id} - Libertas`);
        setIsLoading(false);
      }
      return response;
    }
  };
  const persistViewContract = async () => {
    if (requestRetryCount < 10) {
      if (!isFetching && contract?.pdfPath === null) {
        await sleep(2000 * requestRetryCount);
        setRequestRetryCount(count => count * 2);
        fetchData();
      } else {
        setRequestRetryCount(1);
      }
    }
  };

  useEffect(() => {
    persistViewContract();
  }, [contract, isFetching]);

  useEffect(() => {
    if (location.pathname.includes("occurrence")) {
      setIsContractOccurrencesDrawerVisible(true);
    } else {
      setIsContractOccurrencesDrawerVisible(false);
    }
  }, [location.pathname]);

  function closeDocumentModal() {
    setIsVisibleDocumentModal(false);
  }

  const reloadState = async ({ changeFetchingState = true } = {}) => {
    if (isReloading.current) {
      return;
    }

    isReloading.current = true;

    changeFetchingState && setIsFetching(true);
    const response = await requestContract();

    if (response.error) {
      setMessage({
        message:
          response.error.message ??
          "Não foi possível localizar o contrato, por favor atualize a página e tente novamente",
        type: messageBarTypes.ERROR
      });

      return;
    }

    const { data } = response;

    if (data.approvalFlow.steps) {
      setSteps(data.approvalFlow.steps);
    }

    data.approvalFlow.steps.forEach(step => {
      if (step.internalName === data.currentStep.internalName) {
        setCurrentStep(step.order);
      }
    });

    if (data.uploads && data.uploads.length > 0) {
      setUploads(data.uploads);
    }
    setNextStep(data.nextStep);
    setContract(data);
    changeFetchingState && setIsFetching(false);

    isReloading.current = false;
    return data;
  };

  function handleFinishChangeStatus() {
    reloadState();
    setIsVisibleActionModal(false);
  }
  const changeContractStatusRequest = ({ action }: { action: string }) => {
    changeContractStatus({
      contractId: contract.id,
      action,
      setMessage,
      setIsFetching,
      helperFunction: handleFinishChangeStatus,
      statusChangeReason
    });
  };

  useEffect(() => {
    if (statusChangeReason !== "" && activeStatusChangeRequest) {
      changeContractStatusRequest({
        action: action
      });
      setActiveStatusChangeReason(false);
    }
  }, [
    statusChangeReason,
    action,
    changeContractStatusRequest,
    activeStatusChangeRequest
  ]);

  function openDrawer() {
    setDrawerState({ action: DrawerActions.VIEW, isOpen: true });
  }

  function closeDrawer() {
    setDrawerState({ action: "", isOpen: false });
  }

  async function toggleVisibleActionModal(actionType?: string) {
    if (actionType === "reject" || actionType === "cancel") {
      await openModalWithSettings(
        actionMessage
          ? `Deseja ${actionMessage} ?`
          : "Deseja realizar essa operação?",
        actionConfirmText[action],
        () => {
          setActiveStatusChangeReason(true);
        },
        {
          maxWidth: 500,
          customComponent: () => (
            <CancelOrRejectJustificationContent
              setValue={value => {
                value?.cancel_reason &&
                  setStatusChangeReason(value?.cancel_reason);
              }}
              paymentRequests={contract.paymentRequest}
            />
          )
        }
      );
    }
    if (!actionType) {
      setIsVisibleActionModal(prevState => !prevState);
    }
  }

  useEffect(() => {
    setButtonDisabled(isFetching);
  }, [isFetching]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {message && (
        <MessageBarCustom message={message} dismissMessage={cleanMessage} />
      )}
      {isLoading ? (
        <ProgressIndicator
          styles={{
            root: { position: "absolute", left: 0, right: 0, top: 0 },
            itemProgress: { padding: 0 }
          }}
        />
      ) : (
        <Stack tokens={{ childrenGap: 15 }} style={{ position: "relative" }}>
          <ViewMenu
            contract={contract}
            nextStep={nextStep}
            reloadState={reloadState}
            setIsVisible={setIsVisibleDocumentModal}
            setIsContractOccurrencesDrawerVisible={() =>
              history.push(`${location.pathname}/occurrences`)
            }
            action={action}
            setAction={setAction}
            setActionMessage={setActionMessage}
            toggleActionModal={toggleVisibleActionModal}
            contractId={contractId}
            isButtonDisabled={isButtonDisabled}
          />
          <div className="ms-Grid" dir="ltr">
            {/* SHOW CONTRACT NOTES */}
            {contract.notes && (
              <MessageBar isMultiline>{contract.notes}</MessageBar>
            )}
            {/* ViewProgress SHOW THE CONTRACT PROGRESS */}
            <ViewProgress currentStep={currentStep} steps={steps} />
            {/* DisplayContractInfo SHOW INFORMATIONS ABOUT THE CONTRACT */}
            <DisplayContractInfo
              clientInfoTitle={"Cooperado"}
              contractInfoTitle={"Informações"}
              className="ms-sm12 ms-xl5"
              contract={contract}
              refetchContractData={reloadState}
              uploads={uploads}
              nextStep={nextStep}
              setIsVisible={setIsVisibleDocumentModal}
              openDrawer={openDrawer}
              setAction={setAction}
              setActionMessage={setActionMessage}
              toggleActionModal={toggleVisibleActionModal}
              isEditingEnabled
              activationFeePaymentInfo={
                selectedBU?.settings?.activationFeePaymentInfo
              }
              onOpenRenewalProductsModal={() =>
                setIsProductRenewalModalOpen(true)
              }
              invoiceInfos={invoiceInfos}
            />
          </div>

          <Panel
            headerText="Histórico do contrato"
            isOpen={isContractOccurrencesDrawerVisible}
            onDismiss={() =>
              history.push(location.pathname.replace("/occurrences", ""))
            }
            type={PanelType.medium}
            closeButtonAriaLabel="Fechar"
            isLightDismiss
          >
            <ContractOccurrences contractId={contractId} />
          </Panel>

          <CustomDrawer
            isOpen={drawerState.isOpen}
            action={drawerState.action}
            module={DrawerModules.ACCOUNT}
            onCloseDrawer={closeDrawer}
            size="medium"
            drawerContainerRef={drawerContainerRef}
          >
            {drawerState.action === DrawerActions.VIEW && (
              <ViewClient
                contractInfo={contract}
                addressIdInUse={contract?.accountAddress?.id}
                contactIdInUse={contract?.accountContact?.id}
                checkingAccountIdInUse={contract?.checkingAccount?.id}
                documentIdInUse={contract?.accountDocument?.id}
                secondaryDocumentIdInUse={
                  contract?.account?.secondaryDocument?.id
                }
                closeDrawer={closeDrawer}
                drawerContainerRef={drawerContainerRef}
              />
            )}
          </CustomDrawer>

          <UploadDocumentModal
            closeDocumentModal={closeDocumentModal}
            isVisibleDocumentModal={isVisibleDocumentModal}
            contract={contract}
            setUploads={setUploads}
            match={props?.match}
            requestContract={fetchData}
          />

          <Dialog
            hidden={!isVisibleActionModal}
            onDismiss={() => toggleVisibleActionModal()}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Confirmar ação no contrato",
              closeButtonAriaLabel: "Fechar"
            }}
            modalProps={{
              isBlocking: true,
              containerClassName: "dialog-container"
            }}
          >
            <Text variant="mediumPlus">
              {actionMessage
                ? `Deseja ${actionMessage}?`
                : "Deseja realizar essa operação?"}
            </Text>
            {actionConfirmText[action] && (
              <Text variant="small">{actionConfirmText[action]}</Text>
            )}
            <DialogFooter>
              <DefaultButton
                text="Não"
                onClick={() => toggleVisibleActionModal()}
              />
              <PrimaryButton
                id="confirm-release-credit-button"
                onClick={() => changeContractStatusRequest({ action })}
                text="Sim"
                disabled={isFetching}
              />
            </DialogFooter>
          </Dialog>

          <ContractProductsRenewalModal
            isOpen={isProductRenewalModalOpen}
            onDismiss={() => setIsProductRenewalModalOpen(false)}
            serviceCategory={contract.serviceCategory}
            service={contract.service}
            businessUnit={contract.businessUnit}
            items={contract.items}
            contractId={contract.id}
          />

          {isFetching && <Loader />}
        </Stack>
      )}
    </>
  );
}
