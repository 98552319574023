import { DetailsList, Label, SelectionMode, Text } from "@fluentui/react";
import { useMemo } from "react";

import { SelectedItemToRenew } from "./ContractProductsRenewalModal";
import {
  ContractProductsRenewalReviewContainer,
  ContractProductsRenewalReviewInfoTitle,
  ContractProductsRenewalReviewWrapper
} from "./styles/ContractProductsRenewalReview.styles";

const SignatureTypeDisplay: Record<"STANDARD" | "ELETRONIC", string> = {
  STANDARD: "Manual",
  ELETRONIC: "Digital"
};

interface ContractProductsRenewalReviewProps {
  serviceCategoryType: "MOBILE_LINE" | "BROADBAND" | "TRACKER" | "TAG";
  signatureType: "STANDARD" | "ELETRONIC";
  items: any[];
  selectedItems: SelectedItemToRenew[];
  carriers: any[];
}

function ContractProductsRenewalReview({
  // serviceCategoryType,
  signatureType,
  items,
  selectedItems,
  carriers
}: ContractProductsRenewalReviewProps) {
  const listItems = useMemo(() => {
    const itemsFiltered = items.filter(
      item =>
        selectedItems.findIndex(
          selectedItem => selectedItem.itemId === item.id
        ) !== -1
    );

    const offers = carriers.flatMap(carrier =>
      carrier.plans.flatMap(plan =>
        plan.offers.map(offer => ({
          planName: plan.name.trim(),
          offerPriceId: offer.offerPrice.id,
          price: offer.offerPrice.price
        }))
      )
    );

    return itemsFiltered.map(item => {
      const selectedItem = selectedItems.find(
        selectedItem => selectedItem.itemId === item.id
      );

      if (selectedItem) {
        return {
          id: item.id,
          vehicleLicensePlate: item.additionalInfo.vehicleLicensePlate,
          serialNumber: item.additionalInfo.serialNumber,
          ...offers.find(
            offer => offer.offerPriceId === selectedItem.offerPriceId
          )
        };
      }
    });
  }, [carriers, items, selectedItems]);

  return (
    <ContractProductsRenewalReviewContainer>
      <Label>Revisão</Label>

      <Text as="p" block>
        Um novo contrato será criado com os benefícios
        selecionado(s). O tipo de assinatura não poderá ser alterado após a
        confirmação.
      </Text>

      <Text block>
        <ContractProductsRenewalReviewInfoTitle>
          Tipo de assinatura:
        </ContractProductsRenewalReviewInfoTitle>{" "}
        {SignatureTypeDisplay[signatureType]}
        <br />
        <ContractProductsRenewalReviewInfoTitle>
          Quantidade:
        </ContractProductsRenewalReviewInfoTitle>{" "}
        {selectedItems.length}{" "}
        {selectedItems.length > 1 ? "benefícios" : "benefício"}
      </Text>

      <ContractProductsRenewalReviewWrapper>
        <DetailsList
          setKey="id"
          selectionMode={SelectionMode.none}
          items={listItems}
          columns={[
            {
              key: "serialNumber",
              name: "Número de série",
              minWidth: 80,
              maxWidth: 150,
              isRowHeader: true,
              onRender: item => item.serialNumber.match(/.{1,4}/g).join(" ")
            },
            {
              key: "vehicleLicensePlate",
              name: "Placa",
              minWidth: 50,
              maxWidth: 60,
              onRender: item =>
                item.vehicleLicensePlate.replace(/(.{3})(.)/, "$1-$2")
            },
            {
              key: "planName",
              name: "Plano de benefícios",
              fieldName: "planName",
              minWidth: 50
            },
            {
              key: "price",
              name: "Valor",
              minWidth: 100,
              maxWidth: 100,
              onRender: item =>
                `${Number(item.price).toLocaleString("pt-BR", {
                  currency: "BRL",
                  style: "currency"
                })}/mês`
            }
          ]}
        />
      </ContractProductsRenewalReviewWrapper>
    </ContractProductsRenewalReviewContainer>
  );
}

export default ContractProductsRenewalReview;
