import { getTheme } from "@fluentui/react";
import styled from "styled-components";

type CardProps = {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  borderRadius?: string;
};

const theme = getTheme();

export const Card = styled.div<CardProps>`
  background: ${theme.palette.white};
  box-shadow: ${theme.effects.elevation4};
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  border-radius: ${props => props.borderRadius || theme.effects.roundedCorner4};
`;
