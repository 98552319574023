import {
  ColumnActionsMode,
  getTheme,
  IColumn,
  IconButton
} from "@fluentui/react";

import { ContractColumns } from "./contractColumns/ContractColumns";

import { DrawerActions } from "@/components/Shared/CustomDrawer";

export function BuildContractColumns({
  canRead,
  openDrawer,
  setSelectedItemId
}) {
  const theme = getTheme();

  const columns: IColumn[] = ContractColumns({
    canRead
  });

  if (canRead) {
    columns.unshift({
      key: "view",
      name: "view",
      fieldName: "view",
      minWidth: 60,
      maxWidth: 60,
      columnActionsMode: ColumnActionsMode.disabled,
      isIconOnly: true,
      onRender: item => {
        const isContractInProgress =
          item.currentStep.internalName === "IN_PROGRESS";

        return (
          <IconButton
            onClick={() => {
              if (!isContractInProgress) {
                setSelectedItemId(item.id);
                openDrawer(DrawerActions.VIEW);
              }
            }}
            iconProps={{
              iconName: isContractInProgress ? "Hide3" : "View"
            }}
            style={{
              color: isContractInProgress ? theme.palette.neutralTertiary : "",
              cursor: isContractInProgress ? "not-allowed" : ""
            }}
            title={
              isContractInProgress
                ? "Visualização Rápida Não Disponível"
                : "Visualizar Contrato"
            }
          />
        );
      }
    });
  }

  return columns;
}
