import {
  Dialog,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  DialogType,
  MessageBarType,
  Shimmer,
  ShimmerElementType
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack } from "@fluentui/react/lib/Stack";
import { useEffect, useState } from "react";

import { DefaultCardTitle } from "../styles";

import SignatureTable from "./SignatureTable";

import { AlertTypes } from "@/common/types/AlertTypes";
import { Card } from "@/components/Shared/Card";
import { ContractsService } from "@/core/libs/api";
import { useAlert } from "@/hooks/useAlert";

const contractsService = ContractsService();

interface ContractSignaturesProps {
  contractId: string;
  eletronicSignature?: any;
}

export function ContractSignatures({
  contractId,
  eletronicSignature
}: ContractSignaturesProps) {
  const { createAlert } = useAlert();

  const [canResendSignatureRequest, setCanResendSignatureRequest] = useState(
    () =>
      eletronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [resendButtonIsLoading, setResendButtonIsLoading] = useState(false);

  useEffect(() => {
    setCanResendSignatureRequest(
      eletronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
    );
  }, [eletronicSignature]);

  function showConfirmResendSignatureRequest() {
    setIsDialogOpen(true);
  }

  function handleResend() {
    const { signal } = new AbortController();
    setResendButtonIsLoading(true);
    setIsDialogOpen(false);

    const successAlert = {
      timeout: 4000,
      level: MessageBarType.success,
      message: "Assinaturas reenviadas com sucesso!",
      type: AlertTypes.DEFAULT
    };

    const failureAlert = {
      timeout: 4000,
      level: MessageBarType.error,
      message: "Não foi possível reenviar assinaturas pendentes.",
      type: AlertTypes.DEFAULT
    };

    contractsService
      .resendSignatures({ id: contractId, signal })
      .then(({ error }) => {
        if (error) {
          createAlert(failureAlert);
        } else {
          createAlert(successAlert);
        }
      })
      .catch(() => {
        createAlert(failureAlert);
      })
      .finally(() => {
        setResendButtonIsLoading(false);
      });
  }

  return (
    <Stack verticalFill>
      <Stack horizontal horizontalAlign="space-between">
        <DefaultCardTitle>
          <Icon iconName="OpenEnrollment" style={{ marginRight: 10 }} />
          Assinaturas
        </DefaultCardTitle>
        {resendButtonIsLoading ? (
          <Shimmer
            shimmerElements={[
              { type: ShimmerElementType.line, width: "96px", height: 32 }
            ]}
          />
        ) : (
          <DefaultButton
            data-testid="resend-documents-button"
            disabled={!canResendSignatureRequest}
            onClick={showConfirmResendSignatureRequest}
          >
            Reenviar
          </DefaultButton>
        )}
      </Stack>

      <Card borderRadius="1rem" padding="1rem">
        <SignatureTable signatures={eletronicSignature} />
      </Card>

      <Dialog
        isOpen={isDialogOpen}
        onDismiss={() => setIsDialogOpen(false)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: "Confirmação",
          showCloseButton: true,
          subText:
            "Você tem certeza que deseja reenviar as assinaturas pendentes?"
        }}
      >
        <DialogFooter>
          <DefaultButton onClick={() => setIsDialogOpen(false)}>
            Cancelar
          </DefaultButton>
          <PrimaryButton onClick={handleResend}>Reenviar</PrimaryButton>
        </DialogFooter>
      </Dialog>
    </Stack>
  );
}
