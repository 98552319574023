import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import { Link, useHistory } from "react-router-dom";

import { MembershipValidationResponse } from "./interface";
import { ButtonContainer, Title, Wrapper } from "./styles";

interface IMembershipValidationModal {
  membershipValidationData: MembershipValidationResponse;
  showTermModal: (status: boolean) => void;
}

export function MembershipValidationModal({
  membershipValidationData,
  showTermModal
}: IMembershipValidationModal): JSX.Element {
  const history = useHistory();

  const finishBackofficeTerm = (
    <Stack>
      <Title>Não foi possível confirmar contrato</Title>

      <p>
        Para confirmar o contrato, é necessário finalizar a associação inciada
        por meio do Portal do Cooperado.
      </p>

      <ButtonContainer>
        <DefaultButton id="button-cancel" onClick={() => showTermModal(false)}>
          Cancelar
        </DefaultButton>
      </ButtonContainer>
    </Stack>
  );

  const term = membershipValidationData?.term;
  const finishLibertasTerm = (
    <Stack>
      <Title>Termo em andamento</Title>

      <p>Para seguir no contrato é necessário estar associado</p>

      <Link to={`/terms/${term?.id}`}>
        <p>
          Termo <strong>#{term?.id}</strong>
        </p>
      </Link>

      <ButtonContainer>
        <DefaultButton id="button-cancel" onClick={() => showTermModal(false)}>
          Cancelar
        </DefaultButton>

        <PrimaryButton
          id="button-cancel"
          onClick={() => history.push(`/terms/${term?.id}`)}
        >
          Ir para o termo
        </PrimaryButton>
      </ButtonContainer>
    </Stack>
  );

  return (
    <Wrapper>
      {membershipValidationData.hasInitiatedBackofficeTerm
        ? finishBackofficeTerm
        : finishLibertasTerm}
    </Wrapper>
  );
}
