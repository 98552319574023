import { getTheme, Separator, Stack } from "@fluentui/react";

import { ContractItem } from "@/modules/Contracts/types/types";

interface InfosTabProps {
  item: ContractItem;
}

export function InfosCell({ item }: InfosTabProps) {
  const theme = getTheme();
  return (
    <Stack style={{ display: "flex", flexDirection: "column" }}>
      <span
        className="ms-fontWeight-semibold"
        style={{
          color: theme.palette.neutralTertiary
        }}
      >
        {item.businessUnit?.parent?.name}
      </span>
      <span className="ms-fontSize-12">
        {item.businessUnit.name.toUpperCase()}
      </span>

      <Separator />

      <span className="ms-fontSize-12">
        <span style={{ fontWeight: 600 }}>Autor:</span> {item.user.fullName}
      </span>
    </Stack>
  );
}
