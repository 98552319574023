import { useHistory } from "react-router-dom";

import { ClickableDiv } from "../../../styles";

import { AccountCell } from "./cells/accountCell";
import { InfosCell } from "./cells/infosCell";
import { NextStepCell } from "./cells/nextStepCell";

interface ContractColumnsProps {
  canRead: boolean;
}

export function ContractColumns({ canRead }: ContractColumnsProps) {
  const history = useHistory();
  const getContractPath = (id: number, isInProgress: boolean) =>
    `/contracts/${id}${isInProgress ? "/edit" : ""}`;

  let isInProgress = false;

  const columns = [
    {
      key: "id",
      name: "Código",
      minWidth: 20,
      maxWidth: 50,
      fieldName: "id",
      isRowHeader: true,
      data: "string"
    },
    {
      key: "account",
      name: "Cooperado",
      minWidth: 200,
      fieldName: "account",
      isRowHeader: true,
      data: "string",
      isPadded: true,
      isMultiline: true,
      onRender: item => {
        isInProgress = item.currentStep?.internalName === "IN_PROGRESS";

        return (
          <AccountCell
            canRead={canRead}
            item={item}
            isInProgress={isInProgress}
          />
        );
      }
    },
    {
      key: "items",
      name: "Itens",
      minWidth: 50,
      maxWidth: 50,
      fieldName: "items",
      isRowHeader: true,
      onRender: item => {
        return (
          <ClickableDiv
            onClick={() =>
              canRead
                ? history.push(getContractPath(item.id, isInProgress))
                : null
            }
          >
            {item.items}
          </ClickableDiv>
        );
      }
    },
    {
      key: "nextStep",
      name: "Estado",
      minWidth: 160,
      maxWidth: 160,
      fieldName: "nextStep",
      isRowHeader: true,
      isPadded: true,
      isMultiline: true,
      onRender: item => {
        return <NextStepCell item={item}></NextStepCell>;
      }
    },
    {
      key: "infos",
      name: "Informações",
      minWidth: 200,
      maxWidth: 200,
      fieldName: "infos",
      isRowHeader: true,
      isMultiline: true,
      onRender: item => {
        return <InfosCell item={item} />;
      }
    },
    {
      key: "createdAt",
      name: "Data",
      fieldName: "createdAt",
      isRowHeader: true,
      minWidth: 100,
      data: "string",
      onRender: ({ createdAt }) => {
        if (createdAt) {
          return (
            <div>
              {new Intl.DateTimeFormat("pt-BR").format(new Date(createdAt))}
            </div>
          );
        }
      }
    }
  ];

  return columns;
}
