import { Check, Stack } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import { Nav } from "@fluentui/react/lib/Nav";
import { MouseEvent, useCallback, useEffect, useState } from "react";

type GroupedTreeProps = {
  groupedTreeConfig: {
    label: string;
    name: string;
    type: string;
    required?: boolean;
  };
  values?: number[];
  groups: {
    id: number;
    name: string;
    isAutonomous: boolean;
    children: {
      isAutonomous: boolean;
      id: number;
      name: string;
    }[];
  }[];
  errors: any;
  touched: any;
  setFieldValue: (fieldName: string, options: number[]) => void;
  disabled: boolean;
};

export function GroupedTree({
  errors,
  groupedTreeConfig,
  groups,
  setFieldValue,
  touched,
  values,
  disabled
}: GroupedTreeProps): JSX.Element {
  const [selectedItems, setSelectedItems] = useState([]);
  const [navLinks, setNavLinks] = useState([]);
  const buildTree = useCallback(
    (itemLink, { parents }) => {
      if (itemLink?.businessUnits && itemLink.businessUnits?.length > 0) {
        const currentLink = {
          name: (
            <span>
              <b>Organização: </b> {itemLink.name}
            </span>
          ),
          url: "",
          isAutonomous: true,
          key: itemLink.id,
          links: [],
          parents: parents,
          isExpanded: true,
          isOrganization: true,
          disabled:
            typeof disabled === "boolean" ? disabled : itemLink.isDisabled,
          id: `link-${itemLink.id}`
        };

        if (itemLink.businessUnits.length > 0) {
          itemLink.businessUnits.forEach(subMenu => {
            if (currentLink.parents) {
              currentLink.links.push(
                buildTree(subMenu, {
                  parents: [itemLink.id, ...currentLink.parents]
                })
              );
            } else {
              currentLink.links.push(
                buildTree(subMenu, {
                  parents: !currentLink?.isOrganization ? [itemLink.id] : []
                })
              );
            }
          });
        }

        return currentLink;
      }
      if (itemLink.isAutonomous && itemLink.children.length > 0) {
        const currentLink = {
          name: itemLink.name,
          url: "",
          isAutonomous: true,
          key: itemLink.id,
          links: [],
          parents: parents,
          isExpanded: false,
          disabled:
            typeof disabled === "boolean" ? disabled : itemLink.isDisabled,
          id: `link-${itemLink.id}`
        };

        if (itemLink.children.length > 0) {
          itemLink.children.forEach(subMenu => {
            if (currentLink.parents) {
              currentLink.links.push(
                buildTree(subMenu, {
                  parents: [itemLink.id, ...currentLink.parents]
                })
              );
            } else {
              currentLink.links.push(
                buildTree(subMenu, {
                  parents: [itemLink.id]
                })
              );
            }
          });
        }

        return currentLink;
      } else {
        return {
          name: itemLink.name,
          url: "",
          key: itemLink.id,
          disabled:
            typeof disabled === "boolean" ? disabled : itemLink.isDisabled,
          parents: parents,
          id: `link-${itemLink.id}`
        };
      }
    },
    [disabled]
  );

  useEffect(() => {
    if (values.length > 0) {
      setSelectedItems(values);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFieldValue(groupedTreeConfig.name, selectedItems);
  }, [selectedItems, groupedTreeConfig.name, setFieldValue]);

  useEffect(() => {
    if (groups.length > 0) {
      const nav = groups.map(itemLink => buildTree(itemLink, { parents: [] }));
      setNavLinks([{ links: nav }]);
    }
  }, [buildTree, groups]);

  const handleLinkClick = (event: MouseEvent, item) => {
    event.preventDefault();

    if (selectedItems.includes(item.key)) {
      if (item.parents) {
        setSelectedItems(prevState =>
          prevState.filter(bu => bu !== item.key && !item.parents.includes(bu))
        );
      } else {
        setSelectedItems(prevState => prevState.filter(bu => bu !== item.key));
      }
    } else {
      setSelectedItems(prevState => [...prevState, item.key]);
    }
  };

  return (
    <>
      <Label required={groupedTreeConfig.required || false}>
        {groupedTreeConfig.label}
      </Label>
      <div style={{ border: "1px solid", borderRadius: "2px", marginTop: 0 }}>
        <Nav
          groups={navLinks}
          selectedKey={"none"}
          onLinkClick={(event, item) => handleLinkClick(event, item)}
          onRenderLink={item => renderItem(item, selectedItems)}
        />
      </div>
    </>
  );
}

function renderItem(item, selectedItems): JSX.Element {
  return (
    <Stack horizontal verticalAlign="center">
      {!item?.isOrganization && (
        <Check
          checked={selectedItems.includes(item?.key)}
          styles={{ root: { marginRight: 5, display: "block" } }}
        />
      )}
      <span>{item?.name}</span>
    </Stack>
  );
}
