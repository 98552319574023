import { DefaultButton, Stack, TextField } from "@fluentui/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

type GoToProps = {
  route: string;
  label?: string;
};

export function GoTo({ route, label }: GoToProps) {
  const [routeId, setRouteId] = useState<string>();
  const history = useHistory();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!routeId) {
      return;
    }
    history.push(`/${route}/${routeId}`);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center">
        <label htmlFor="routeId">{label}</label>
        <TextField
          id="routeId"
          placeholder="ex.: 12345"
          onChange={e => setRouteId(e.currentTarget.value)}
        />
        <DefaultButton type="submit">Ir para</DefaultButton>
      </Stack>
    </form>
  );
}
