import styled from "styled-components";

export const Wrapper = styled.div`
  width: 30rem;
  padding: 2rem;

  p {
    line-height: 1.5rem;
    font-size: 1.1rem;
    color: #201f1e;
  }
`;

export const Title = styled.h1`
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #106ebe;
  text-align: left;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
`;
