import { Label } from "@fluentui/react";
import { FocusTrapZone } from "@fluentui/react/lib/FocusTrapZone";
import { FocusZone } from "@fluentui/react/lib/FocusZone";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Stack } from "@fluentui/react/lib/Stack";
import { Form, Formik, FormikHelpers } from "formik";
import { Component, RefObject, createRef } from "react";

import { FormBuilderSwitcher } from "./form-builder-switcher";
import { FormBuilderStyledLoader } from "./form-builder.styles";
import { Input } from "./types";

import { MessageBar } from "@/core/libs/message-bar";

interface FormBuilderProps {
  fields: any[];
  onSubmit: any;
  validationSchema: any;
  message?: any;
  dismissMessage?: () => void | void;
  dismissPanel?: any;
  isInsidePanel?: boolean;
  linkedGroups?: any;
  formId?: string;
  validateBeforeSubmit?: boolean;
  setReadyToSubmit?: (state: boolean) => void;
  children?: unknown;
}

interface FormBuilderState {
  isFreeformEnabled: boolean;
}

interface Values {
  email: string;
  password: string;
}

export const getNestedObject = (nestedObj, pathArr: Array<string>) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );
};

function createNestedObject(obj, keyPath, value) {
  const lastKeyIndex = keyPath.length - 1;
  for (let i = 0; i < lastKeyIndex; ++i) {
    const key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  obj[keyPath[lastKeyIndex]] = value;
}

class FormBuilder extends Component<FormBuilderProps, FormBuilderState> {
  formRef: RefObject<any>;
  messageBarRef: RefObject<any>;

  constructor(props) {
    super(props);

    this.formRef = createRef();
    this.state = {
      isFreeformEnabled: false
    };
    this.messageBarRef = createRef();
  }

  componentDidMount() {
    this.formRef.current?.focus();
  }

  componentDidUpdate() {
    if (this.props.message && this.props.message !== "") {
      this.messageBarRef.current.focus();
    }
  }

  render() {
    const initialValues = this._getInitialValues(this.props.fields);
    const validationSchema = this.props.validationSchema;
    const dismissPanel = this.props.dismissPanel;
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          const formData = this._clearValues(this.props.fields, values);
          this.props.onSubmit(formData, { setSubmitting }, dismissPanel);
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values
        }) => {
          const setErrors = async () => {
            if (this.props.setReadyToSubmit) {
              await this.props.setReadyToSubmit(false);
              for (const key in errors) {
                await setFieldTouched(key, true);
              }
            }
          };
          if (this.props.validateBeforeSubmit) setErrors();
          const scrollIntoView = () => {
            this.formRef.current.scrollIntoView({ behavior: "smooth" });
          };
          if (isSubmitting && Object.keys(errors).length > 0) scrollIntoView();
          return (
            <Form ref={this.formRef} id={this.props.formId}>
              <Stack tokens={{ childrenGap: 15 }}>
                {this._renderFields(
                  this.props.fields,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                  this.props.linkedGroups
                )}
                <div tabIndex={-1} ref={this.messageBarRef}>
                  {this.props.message && (
                    <MessageBar
                      message={this.props.message}
                      dismissMessage={this.props.dismissMessage}
                    />
                  )}
                </div>
                {this.props.children}
              </Stack>

              {isSubmitting ? (
                <FocusTrapZone disabled={!isSubmitting}>
                  <FocusZone>
                    <FormBuilderStyledLoader data-is-focusable="true">
                      <Spinner size={SpinnerSize.large} />
                    </FormBuilderStyledLoader>
                  </FocusZone>
                </FocusTrapZone>
              ) : null}
            </Form>
          );
        }}
      </Formik>
    );
  }

  private _getInitialValues = inputs => {
    const initialValues: any = {};

    if (inputs) {
      if (this.props.isInsidePanel) {
        inputs.forEach(field => {
          if (field.size) delete field.size;
          // HAS group
          if (field.fields) {
            field.fields.forEach(i => {
              if (i.size) delete i.size;

              if (i.fields) {
                i.fields.forEach(a => {
                  if (a.size) delete a.size;
                });
              }
            });
          }
        });
      }

      inputs.forEach(field => {
        if (field.type && !initialValues[field.name]) {
          initialValues[field.name] = field.value;
        }

        if (!field.type && !initialValues[field.name] && field.fields) {
          field.fields.forEach(i => {
            if (i.type === "basicFields") {
              i.fields.forEach(basicField => {
                const separateNestedName = basicField.name.split(".");
                createNestedObject(
                  initialValues,
                  separateNestedName,
                  basicField.value
                );
              });
            }

            if (i.type !== "basicFields") {
              const separateNestedName = i.name.split(".");
              createNestedObject(initialValues, separateNestedName, i.value);
            }
          });
        }
      });
    }

    return initialValues;
  };

  private _renderField(
    input: Input,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    values,
    linkedGroups
  ) {
    return (
      <div key={input?.key}>
        <FormBuilderSwitcher
          input={input}
          setFieldValue={setFieldValue}
          errors={errors}
          values={values}
          touched={touched}
          linkedGroups={linkedGroups}
          setFieldTouched={setFieldTouched}
        />
      </div>
    );
  }

  private _clearValues(inputs: any[], values: any) {
    const clearedValues = values;
    inputs.forEach(group => {
      if (
        group.showIf &&
        group.showIf.value !== clearedValues[group.showIf.field]
      ) {
        group.fields.forEach(item => {
          if (item.name === "fullName") return;
          delete clearedValues[item.name];
        });
      }
    });
    return clearedValues;
  }

  private _renderGroupedFields(
    input,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    values,
    linkedGroups
  ) {
    return (
      <div key={input?.key || input?.name + "fragment"}>
        {input?.description && (
          <Label key={input.key + "label"} style={{ marginTop: 30 }}>
            {input.description}
          </Label>
        )}
        <Stack horizontal wrap key={input.key} tokens={{ childrenGap: 30 }}>
          {input.fields.map((field, index) => {
            return (
              <Stack.Item
                grow
                key={`${input.key}[${index}]`}
                className={`ms-sm12${field.size ? ` ms-lg${field.size}` : ""}`}
                styles={{
                  root: {
                    //borderBottom: "1px solid #c4c4c4",
                    boxShadow: "0px 0px 5px #c4c4c4",
                    padding: 20,
                    display: field.type === "hidden" ? "none" : "block",
                    background: "#ffffff",
                    boxSizing: "border-box"
                  }
                }}
              >
                {this._renderField(
                  field,
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                  values,
                  linkedGroups
                )}
              </Stack.Item>
            );
          })}
        </Stack>
      </div>
    );
  }

  private _renderFields(
    inputs,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    values,
    linkedGroups
  ) {
    if (inputs) {
      return inputs.map(input => {
        // Don't render conditional fields
        if (input.showIf && input.showIf.value !== values[input.showIf.field])
          return null;

        return input.fields && !input.type
          ? this._renderGroupedFields(
              input,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              values,
              linkedGroups
            )
          : this._renderField(
              input,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              values,
              linkedGroups
            );
      });
    }
  }
}

export default FormBuilder;
