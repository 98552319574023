import { MutableRefObject } from "react";

import {
  DistributionCenterItem,
  ListOfDistributionCenters
} from "./DistributionCenters.types";
import { ListOfProducts, ProductSpecification } from "./Products.types";

import { CommomListProps } from "@/common/types/ListFromApi.types";
import { IMessageBar } from "@/core/libs/message-bar";

export type InventoryItem = {
  id?: string;
  availability?: string;
  serialType?: string;
  serialNumber?: string;
  internalCode?: string;
  notes?: string;
  product?: {
    id?: string;
    name?: string;
    ean?: string;
    manageStock?: boolean;
    specifications?: ProductSpecification;
  };
  distributionCenter?: DistributionCenterItem;
  selfSales?: boolean;
};

export interface ListOfInventories extends CommomListProps {
  items?: InventoryItem[];
}

export enum InventoryAvailabilityBadgeStatusEnum {
  RESERVED = "#FF9800",
  WITHDRAWN = "#2196F3",
  AVAILABLE = "#4CAF50",
  PENDING = "#f1c232",
  SOLD = "#F44336"
}

export enum InventoryAvailabilityTranslatedEnum {
  RESERVED = "Reservado",
  WITHDRAWN = "Retirado",
  AVAILABLE = "Disponível",
  PENDING = "Pendente",
  SOLD = "Vendido"
}

export enum InventoryAvailabilityEnum {
  RESERVED = "RESERVED",
  WITHDRAWN = "WITHDRAWN",
  AVAILABLE = "AVAILABLE",
  PENDING = "PENDING",
  SOLD = "SOLD"
}

export enum InventorySerialTypeEnum {
  IMEI = "IMEI",
  ICCID = "ICCID",
  OTHERS = "OTHERS"
}

export enum InventorySerialTypeTranslatedEnum {
  IMEI = "IMEI",
  ICCID = "ICCID",
  OTHERS = "Outros"
}

export type InventoriesProps = {
  canCreate: boolean;
  canEdit: boolean;
  canTransfer: boolean;
};

export type CreateProps = {
  create: (values, { setSubmitting }, closePanel) => void;
  dismissMessage: () => void;
  closePanelAndMessage: () => void;
  listProductsRequest: () => void;
  listDistributionCenterRequest: () => void;
  message: IMessageBar;
  isLoadingProductList: boolean;
  productsList: ListOfProducts;
  isLoadingDistributionCenterList: boolean;
  distributionCenterList: ListOfDistributionCenters;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};

export type EditProps = {
  edit: (values, { setSubmitting }, closePanel) => void;
  dismissMessage: () => void;
  closePanelAndMessage: () => void;
  listProductsRequest: () => void;
  listDistributionCenterRequest: () => void;
  message: IMessageBar;
  selectedItemId: string;
  isLoadingProductList: boolean;
  productsList: ListOfProducts;
  isLoadingDistributionCenterList: boolean;
  distributionCenterList: ListOfDistributionCenters;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
};
