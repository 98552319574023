import { SelectionMode, Stack } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { getAndSetUsers } from "../Settings/pages/BusinessUnits/commonAPIRequest";

import { dismissMessage } from "./actions/contracts";
import { requestOperations } from "./api";
import { BuildContractColumns } from "./components/buildContractColumns/BuildContractColumns";
import { getFiltersConfig } from "./constants/contract-filters";
import { contractStatusOptions } from "./constants/contract-status-options";
import { paymentMethodOptions } from "./constants/payment-method-options";
import { IconNames, TranslatedContractNextStep } from "./enums";
import { ContractProps } from "./types";

import { FilterParamsType } from "@/common/types/ListFromApi.types";
import { ViewContract } from "@/components/Contracts/ViewContract";
import { GoTo } from "@/components/goTo";
import { Card } from "@/components/Shared/Card";
import {
  CustomDrawer,
  DrawerActions,
  DrawerActionsType,
  DrawerModules
} from "@/components/Shared/CustomDrawer";
import { useGetContracts } from "@/core/libs/api/react-query/hook/use-get-contracts";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { ListData } from "@/core/libs/list-data";
import { FiltersProps } from "@/core/libs/list-data/lib/types";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { useURLFilter } from "@/hooks/useUrlFIlter";

const loadingDropdownOptions = [
  { key: "loading", text: "Carregando...", disabled: true }
];

function Contracts({ canRead, canCreate }: ContractProps): JSX.Element {
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const [drawerState, setDrawerState] = useState({
    action: null,
    isOpen: false
  });
  const [selectedItemId, setSelectedItemId] = useState<number>(undefined);
  const [pendingContractOptions, setPendingContractOptions] =
    useState<{ text: string; queryValue: string; count: string }[]>(null);
  const [contractOperationsOptions, setContractOperationsOptions] =
    useState(null);
  const [salesTeamsOptions, setSalesTeamsOptions] = useState(null);
  const [usersFilterOptions, setUserFilterOptions] = useState(null);
  const { changeUrlParams, urlParams } = useURLFilter();
  const history = useHistory();
  const { execute } = useApi();

  const { contractsList } = useGetContracts(urlParams.params);

  const { selectedBU } = useSelectedBu();

  const dispatch = useDispatch();

  const [pendingList, setPendingList] = useState(null);
  const filtersConfig = getFiltersConfig({
    contractStatusOptions,
    paymentMethodOptions,
    contractOperationsOptions,
    salesTeamsOptions,
    usersFilterOptions,
    loadingDropdownOptions
  });

  const filters: Array<FiltersProps> = useMemo(
    () => filtersConfig,
    [filtersConfig]
  );

  const handleContractsRequest = useCallback(
    (filterParams?: FilterParamsType) => {
      setPendingList(true);
      changeUrlParams({
        ...filterParams,
        businessUnitSelected: selectedBU?.id
      });
      setPendingList(false);
    },
    [changeUrlParams, selectedBU]
  );

  const listAndSetSalesTeam = useCallback(async () => {
    const result = await execute({
      url: `sales-teams`,
      notificationMessage: "Buscando Times comerciais no dashboard de contratos"
    });

    if (result.data) {
      const formattedSalesTeams = result.data.items.map(org => {
        return { key: org.id, text: org.name };
      });

      setSalesTeamsOptions(formattedSalesTeams);
    }
  }, [execute]);

  useEffect(() => {
    requestOperations({
      selectedBUId: selectedBU?.id,
      setContractOperationsOptions
    });
    listAndSetSalesTeam();
    getAndSetUsers({ setUserFilterOptions });
  }, [selectedBU?.id, listAndSetSalesTeam]);

  useEffect(() => {
    if (contractsList?.pendingContracts) {
      const formattedPendingContractOptions =
        contractsList.pendingContracts.map(contract => ({
          text: TranslatedContractNextStep[contract.internalName],
          iconName: IconNames[contract.internalName],
          queryValue: contract.internalName,
          count: contract.qty
        }));

      setPendingContractOptions(formattedPendingContractOptions);
    }
  }, [contractsList]);

  function handleDismissMessage() {
    dispatch(dismissMessage());
  }

  function openDrawer(action: DrawerActionsType) {
    setDrawerState({ action: action, isOpen: true });
  }

  function closeDrawer() {
    setDrawerState({ action: null, isOpen: false });
  }

  return (
    <Stack styles={{ root: { width: "100%" } }}>
      <Stack tokens={{ childrenGap: 15 }}>
        <Card padding="1rem 0">
          <div style={{ padding: "1rem" }}>
            <Helmet>
              <title>Contratos - Libertas</title>
            </Helmet>
            <Stack horizontal horizontalAlign="space-between">
              <h2 className="ms-fontSize-24">Contratos</h2>
              <GoTo route="contracts" label="Ir para contrato" />
            </Stack>
          </div>
          <ListData
            headerAction={
              canCreate ? () => history.push("/contracts/create") : undefined
            }
            headerText="Contrato"
            isLoadingList={pendingList}
            items={contractsList?.contracts || []}
            selectionMode={SelectionMode.none}
            columns={BuildContractColumns({
              canRead,
              openDrawer,
              setSelectedItemId
            })}
            hasFilters
            isDefaultFilterVisible
            filters={filters}
            headerFilterQueryName="pendingStatus"
            headerFilters={pendingContractOptions}
            menuItems={undefined}
            setSelectedItem={setSelectedItemId}
            _paginate={handleContractsRequest}
          />
        </Card>

        <CustomDrawer
          isOpen={drawerState.isOpen}
          action={drawerState.action}
          module={DrawerModules.CONTRACT}
          onCloseDrawer={closeDrawer}
          size="medium"
          drawerContainerRef={drawerContainerRef}
        >
          {drawerState.action === DrawerActions.VIEW && canRead && (
            <ViewContract
              initialContractId={selectedItemId}
              currentContractList={
                Array.isArray(contractsList?.contracts)
                  ? contractsList.contracts
                  : contractsList?.contracts?.["items"]
              }
              currentPage={
                !Array.isArray(contractsList?.contracts) &&
                contractsList?.contracts?.["meta"]["currentPage"]
              }
              totalPages={
                !Array.isArray(contractsList?.contracts) &&
                contractsList?.contracts?.["meta"]["totalPages"]
              }
              listContractRequest={handleContractsRequest}
              dismissMessage={handleDismissMessage}
              closeDrawer={closeDrawer}
              drawerContainerRef={drawerContainerRef}
            />
          )}
        </CustomDrawer>
      </Stack>
    </Stack>
  );
}

export default Contracts;
